<template>
    <div class="pa-0 ma-0">
        <v-row class="ma-0 pa-0">
            <v-col cols="12" xs="12" sm="12" md="12" class="px-2">
                <v-breadcrumbs :items="itemsLinks">
                    <template v-slot:divider>
                        <v-icon>chevron_right</v-icon>
                    </template>
                </v-breadcrumbs>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" class="mx-5">
                
                    <div class="mt-0 d-flex">
                        <div class="titulo-div">
                        </div>
                        <div>
                            <div class="titulo-sub pr-2">Detalle de LargeFaceList</div>
                        </div>
                    </div>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" class="mx-5 pa-3">
                <v-card
                    class="elevation-0 border-table mr-5"

                >
                    <v-card-title
                        class="mx-3"
                    >
                        <div></div>
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Buscar"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="itemsEmpleados"
                        :search="search"
                        :footer-props="{
                            'items-per-page-options':[10,50,100, -1],
                            'items-per-page-text':'Mostrar',
                            'items-per-page-all-text':'Todos',
                        }"
                        :items-per-page="10"
                        class="mx-5 borde-tabla"
                        :item-class="rowClass"
                        :loading="loadingTable"
                        :loading-text="'Cargando...'"
                    >
                        <!-- <template slot="body" slot-scope="props">
                            <tr v-for="(item, index) in props.items">
                                <td>{{ item.nombre }}</td>
                                <td>{{ item.uuid }}</td>
                            </tr>
                        </template> -->
                        <!-- <template v-slot:body="{ items }">
                            <tbody>
                                <tr
                                    v-for="item in items"
                                    :key="item.name"
                                >
                                    <td>{{ item.nombre }}</td>
                                    <td>{{ item.hey }}</td>
                                </tr>
                            </tbody>
                        </template> -->

                        <template v-slot:item.acciones="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                <v-btn v-on="on" @click="eliminar(item)" class="botonHover" icon fab dark small>
                                    <v-icon class="material-icons tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                </v-btn>
                                </template>
                                <span>Eliminar</span>
                            </v-tooltip>
                        </template>

                        <template v-slot:no-data>
                            <p class="mt-2 pt-3">No hay elementos para mostrar.</p>
                        </template>
                        <template v-slot:no-results>
                            <p class="mt-2 pt-3">No hay elementos para mostrar.</p>
                        </template>
                        <template v-slot:[`footer.page-text`]="items"> 
                            {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import AzureCognitiveService from "@/api/cognitiveServices";
import Notify from '@/plugins/notify';


export default {
    data() {
        return {
            search: '',
            headers: [
                {
                    text: 'Id empleado',
                    align: 'start',
                    sortable: false,
                    value: 'userData',
                    class:['table-vuetify-header', 'table-vuetify-header-first-th'],
                },
                { 
                    text: 'UUID face', 
                    value: 'persistedFaceId',
                    class:['table-vuetify-header'],
                },
                { 
                    text: 'Acciones', 
                    value: 'acciones',
                    sortable: false,
                    class:['table-vuetify-header', 'table-vuetify-header-last-th'],
                },
                
            ],
            itemsLinks      : [
                {
                    text: 'Largefacelists',
                    disabled: false,
                    to: '/largefacelists',
                },
                {
                    text: 'Detalle de rostros',
                    disabled: true,
                    to: '/azureRostros',
                },
            ],
            itemsEmpleados: [],
            idLargeFaceList: null,
            loadingTable: false,
            largeFaceList: null,
        }
    },
    methods: {
        rowClass() {
            const rowClass = 'table-vuetify-body-tr'
            return rowClass;
        },
        editItem(item){

        },
        eliminar(item){
            Notify.Alert(
                "¿Estás seguro de eliminar este Rostro?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del proceso de reconocimiento facial.",
                "Eliminar",
                () => {
                    AzureCognitiveService.deleteFace(this.idLargeFaceList, item.persistedFaceId).then(response => {
                        Notify.Success("Operación exitosa", "El ha sido eliminado satisfactoriamente");
                        this.getRostros();
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },
        getRostros(){
            this.loadingTable = true;
            AzureCognitiveService.getFaces(this.idLargeFaceList).then((response) => {
                this.itemsEmpleados = response;
                this.loadingTable = false;
            })
            .catch(err => {
                this.loadingTable = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },

        agrupar(){
            //Creamos un nuevo objeto donde vamos a almacenar por empleados. 
            let nuevoObjeto = {}
            //Recorremos el arreglo 
            this.arrayRespuesta.forEach( x => {
            //Si el empleado no existe en nuevoObjeto entonces
            //la creamos e inicializamos el arreglo de empleados(fotos). 
            if( !nuevoObjeto.hasOwnProperty(x.userData)){
                nuevoObjeto[x.userData] = {
                fotos: []
                }
            }
            
            //Agregamos los datos de empleado(foto). 
                nuevoObjeto[x.userData].fotos.push({
                    idEmpleado: x.userData,
                    persistedFaceId: x.persistedFaceId
                })
            
            })

            this.itemsEmpleados = nuevoObjeto
            console.log(nuevoObjeto)
        }
    },
    created() {
        //this.agrupar()
        this.largeFaceList = this.$session.get('largeFaceList');
        this.idLargeFaceList = this.largeFaceList.largeFaceListId;
        this.getRostros();
        
    },
    mounted() {
        
    },
}
</script>
<style scoped>
    .border-table {
        border-radius: 20px;
    }
    

</style>